import React from 'react';
import { Link } from "react-router-dom";
import { AuthenticationService } from '../../authenticationService';
import { saveAs } from 'file-saver';

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import { Config } from '../../config';

import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ChangePasswordForm from './ChangePasswordForm';
import OfflineRegistrationForm from './OfflineRegistrationForm';

import {
    Table
} from "react-bootstrap";


class UserAreaView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: AuthenticationService.currentUserValue,
            productInfos: [],
            products: [],
            loading: true
        };

        this.downloadSerialEvent = this.downloadSerialEvent.bind(this);
        this.transferLicenseEvent = this.transferLicenseEvent.bind(this);
        this.deleteDeviceEvent = this.deleteDeviceEvent.bind(this);

        this.fetchProducts();
    }

    fetchProducts() {
        fetch(Config.apiUrl + '/product',
            {
                method: 'GET',
                headers: AuthHeader()
            })
            .then(HandleResponse)
            .then(data => {
                this.setState({ products: data });
            })
            .catch(this.redirectToLogin);
    }

    fetchProductInfos() {
        fetch(Config.apiUrl + '/userinfo/products',
            {
                method: 'GET',
                headers: AuthHeader()
            })
            .then(HandleResponse)
            .then(data => {
                this.setState({ productInfos: data, loading: false });
            })
            .catch(this.redirectToLogin);
    }

    redirectToLogin = () => {
        this.props.navigate("/login");
    }

    componentDidMount() {
        this.fetchProductInfos();
    }

    render() {
        let contents = this.state.loading
            ? <div className='loader' key="loader"></div>
            : this.renderData();

        return <div className="container">
            <div>
                {contents}
            </div>
        </div>;
    }

    renderData() {
        const { currentUser } = this.state;
        return (
            <div>
                <div className="newstitle">
                    <div className="well">
                        <h2>User: {currentUser.name}</h2>
                    </div>
                </div>
                <div className="well">
                    <h4>Licenses:</h4>
                    <p />
                    {
                        (this.state.productInfos.length > 0) ?
                            (
                                this.state.productInfos.map(n =>
                                    <div key={n.orderId}>
                                        <div className="simpletableLicense"><h4>{n.name}</h4>
                                            Product Page (downloads and manual): <Link to={`/products/${n.name}`.toLowerCase()}>{n.name}</Link>
                                            {
                                                (n.useRsa2048 && n.serialNew !== "") ?
                                                    (
                                                        <button className="btn btn-secondary" onClick=
                                                            {
                                                                (e) => saveAs(new Blob([n.serialNew], { type: "text/plain;charset=utf-8" }), n.name + "_SerialKey.txt")
                                                            }>Download key for older releases</button>
                                                    )
                                                    :
                                                    (<div />)
                                            }
                                            {
                                                /*
                                                (n.serial.length > 2) ?
                                                    (
                                                        <div>Serial for releases before 2019: {n.serial}<br /></div>
                                                    )
                                                    :
                                                    (
                                                        <div />
                                                    )
                                                        */
                                            }

                                            <br />
                                            <button className="btn btn-secondary" onClick={
                                                (e) => saveAs(new Blob([n.useRsa2048 ? n.serial2048 : n.serialNew], { type: "text/plain;charset=utf-8" }), n.name + "_SerialKey.txt")
                                            }>Download Actual Key</button>
                                            &nbsp;<OfflineRegistrationForm name={n.name} serialKey={n.serial2048} />
                                            &nbsp;<button className="btn btn-secondary" onClick={(e) => this.transferLicenseEvent(e, n.orderId)}>Transfer License...</button>

                                            <br />
                                            <br />

                                            {
                                                (n.orderDevices !== undefined && n.orderDevices.length > 0) &&
                                                (
                                                    <div>
                                                        <h5>Registered computers (max 4):</h5>
                                                    </div>
                                                )
                                            }
                                            {
                                                (n.orderDevices !== undefined && n.orderDevices.length > 0) &&
                                                (
                                                    <div className="Container" >
                                                        <div class="table-responsive">
                                                        <Table className="simpletableDeviceOrder table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Device Name</th>
                                                                    <th scope="col">Device Id</th>
                                                                    <th style={{ textAlign: "right" }} scope="col">Activation Date</th>
                                                                    <th scope="col"></th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    n.orderDevices.map(d =>
                                                                        <tr key={d.id}>
                                                                            <td><b>{d.deviceName}</b></td>
                                                                            <td>{d.deviceIdHash}</td>
                                                                            <td style={{ textAlign: "right" }}>{d.creationDate}</td>
                                                                            <td style={{ textAlign: "right" }}>
                                                                                {
                                                                                    (d.deviceName.includes("Offline")) ?
                                                                                        (
                                                                                            <button className="btn btn-secondary" onClick={
                                                                                                (e) => saveAs(new Blob([d.deviceIdHashEncrypted], { type: "text/plain;charset=utf-8" }), n.name + "_OfflineKey.txt")
                                                                                            }>Download offline Key</button>
                                                                                        ) :
                                                                                        (
                                                                                            <div style={{ width: "90px" }} />
                                                                                        )
                                                                                }
                                                                            </td>
                                                                            <td style={{ textAlign: "right" }}>
                                                                                {
                                                                                    (this.shouldShowDeleteButton(Date.parse(d.creationDate))) ?
                                                                                        (
                                                                                            <button className="btn btn-secondary" onClick={(e) => this.deleteDeviceEvent(e, d.id)}>Delete</button>
                                                                                        ) :
                                                                                        (
                                                                                            <div style={{ width: "90px" }} />
                                                                                        )
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </Table>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                        <div>&nbsp;</div>

                                    </div>
                                ))
                            :
                            <div>No licenses found</div>
                    }
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <hr className='hr' />
                    <h5>Change Password</h5>
                    <ChangePasswordForm />
                    <div />
                </div>
            </div>
        );
    }

    shouldShowDeleteButton(date) {
        var currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        return date < currentDate;
    }

    downloadSerialEvent(event, orderId, productName) {
        return fetch(Config.apiUrl + '/userinfo/serial/' + orderId,
            {
                method: 'GET',
                type: 'text/plain;charset=utf-8',
                headers: AuthHeader()
            })
            .then(HandleResponse)
            .then(serialBlob => {
                var blob = new Blob([serialBlob.toString()], { type: "text/plain;charset=utf-8" });
                saveAs(blob, productName + "_SerialKey.txt")
            });
    }

    async deleteDeviceEvent(event, deviceOrderId) {
        const response = await fetch(Config.apiUrl + '/userinfo/orderdevice/' + deviceOrderId,
            {
                method: 'DELETE',
                headers: AuthHeader()
            });

        await HandleResponse(response);
        window.location.reload();
    }

    transferLicenseEvent(event, orderId) {
        this.props.navigate('/transferlicense/' + orderId, { state: this.props.location.state });
    }
}

const UserAreaViewWithHooks = (props) => (<UserAreaView {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} />);
export default UserAreaViewWithHooks;
