import React, { useEffect, useState } from "react"

import PageView from './PageView';
import MessageView from './MessageView';
import LoginView from './Login/LoginView';
import LoginLostPassword from './Login/LoginLostPassword';
import UserAreaView from './UserArea/UserAreaView';
import ActivationPage from './Activation/ActivationPage';
import AdminPersons from './Person/AdminPersons';
import AdminPerson from './Person/AdminPerson';
import AdminPages from './Page/AdminPages';
import AdminPage from './Page/AdminPage';
import AdminProducts from './Product/AdminProducts';
import AdminProduct from './Product/AdminProduct';
import AdminOrders from './Activation/AdminOrders';
import SupportPage from './SupportPage';
import ResendSerial from './ResendSerial';
import TransferLicense from './UserArea/TransferLicenseView';
import FastSpringUrlCheckout from './FastSpring/FastSpringUrlCheckout';
import { BrowserRouter as Router, Route, NavLink, Routes } from "react-router-dom";
import ShopCartButton from './Shop/ShopCartButton';
import useSizeWidth from '../SizeListener';

import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Config } from '../config';

import { AuthenticationService } from '../authenticationService';

import '../custom.scss';

const Navigation = () => {
  const [menuItems, setMenuItems] = useState([]);
  useSizeWidth(); // important for mobile view icon position -> size listener

  const fetchMenuItems = () => {
    fetch(Config.apiUrl + "/page/menuitems")
      .then(response => response.json())
      .then(data => setMenuItems(data));
  }

  useEffect(() => {
    const handleUserChange = () => {
      fetchMenuItems();
    }

    const doFetch = async () => {
      fetchMenuItems();
      AuthenticationService.currentUser.subscribe(value => handleUserChange());
    }
    doFetch();
  }, []);

  const mobileSizeThreashold = 992;

  const isMobile = () => 
  {
    //alert(windowsize[0]);
    // take the direct window size, the other one not up to date when the first refresh happens
    // TODO: fix this
    return window.innerWidth < mobileSizeThreashold;
  };

  return (
    <Router>
      {/* collapseOnSelect needs eventKey on the navlinks to work */}
      <Navbar collapseOnSelect className="navbar navbar-dark overlay" expand="lg" sticky="top">
        <div className="container" style={{ minWidth: "360px" }}>
          {/* "Link" in brand component since just redirect is needed */}
          { isMobile() && (
            <div style={{ width:"auto", alignContent:"end"}}>
            <ShopCartButton isMobile={true}/>
            </div>)}
          <Navbar.Brand style={{ paddingLeft: !isMobile() ? 30 : 0 }} as={NavLink} to="/">
            <img className="no_shadow"  src="../../logo.svg" alt="TAL" width="160px" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="container-fluid">
                {menuItems.map(n =>
                  <Nav.Link eventKey={n.name} key={n.name} as={NavLink} to={n.url}>{n.name.toUpperCase()}</Nav.Link>
                )}

                <Nav.Link style={{ marginLeft: !isMobile() ? "auto" : 0 }} eventKey="2" key="Support" as={NavLink} to="/support">SUPPORT</Nav.Link>
                {(AuthenticationService.currentUserValue) ? (
                  <Nav.Link eventKey="3" key="UserArea" as={NavLink} to="/userarea">MY TAL</Nav.Link>
                ) : <Nav.Link  eventKey="4" key="Login" as={NavLink} to="/login">LOGIN</Nav.Link>
                }

                {(AuthenticationService.currentUserValue && AuthenticationService.currentUserValue.name === "admin") ? (
                  <NavDropdown key="admin" title="ADMIN" id="basic-nav-dropdown">

                    <NavDropdown.Item href="/admin/persons">PERSONS</NavDropdown.Item>
                    <NavDropdown.Item href="/admin/pages">PAGES</NavDropdown.Item>
                    <NavDropdown.Item href="/admin/products">PRODUCTS</NavDropdown.Item>
                    <NavDropdown.Item href="/admin/orders">ACTIVATION CODES</NavDropdown.Item>
                    <NavDropdown.Item href="/activate">ACTIVATION PAGE</NavDropdown.Item>

                  </NavDropdown>
                ) : <div></div>
                }

                {(AuthenticationService.currentUserValue) && (
                  <Nav.Link key="Logout" as={NavLink} to="/" onClick={() => AuthenticationService.logout()}>
                    { // <i className="material-icons">logout</i> 
                    }
                    LOGOUT</Nav.Link>
                )}
            </Nav>
          </Navbar.Collapse>
          { /* <b>{ window.innerWidth }</b> */ }
          { /* only show shop cart button when not expanded */}
          { !isMobile() && (
            <ShopCartButton isMobile={false}/>)}
        </div>
      </Navbar>
      <Routes>
        <Route path="/admin/resendserial/:productName" element={<ResendSerial />} />
        <Route path="/transferlicense/:id" element={<TransferLicense />} />
        <Route path="/directurlcheckout/:productName?/:couponCode" element={<FastSpringUrlCheckout />} />
        <Route path="/login/lostpassword" element={<LoginLostPassword />} />
        <Route path="/login/" element={<LoginView />} />
        <Route path="/contactsupport" element={<SupportPage />} />
        <Route path="/messageview" element={<MessageView />} />
        <Route path="/userarea" element={<UserAreaView />} />
        <Route path="/activate" element={<ActivationPage />} />
        <Route path="/admin/orders" element={<AdminOrders />} />
        <Route path="/admin/persons" element={<AdminPersons />} />
        <Route path="/admin/products" element={<AdminProducts />} />
        <Route path="/admin/product/:id" element={<AdminProduct />} />
        <Route path="/admin/person/:id" element={<AdminPerson />} />
        <Route path="/admin/page/:id" element={<AdminPage />} />
        <Route path="/admin/pages" element={<AdminPages />} />
        <Route path="/:pathParam1?/:pathParam2?/:pathParam3?" element={<PageView />} />
      </Routes>
    </Router>);
};

export default Navigation;