import React from 'react';
import { useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { Config } from '../../config';

import {
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { HandleResponseNoLogout } from '../../handleResponse';

export default function OfflineRegistrationForm({ name, serialKey }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const formRef = useRef(null); // use this to access form 

  const { register, handleSubmit, setError, formState: { errors } } = useForm();

  const redirectToLogin = () => {
    this.props.navigate("/login");
  }

  function tryToRegisterDevice(deviceIdHash, deviceName) {
    var json = JSON.stringify(
      {
        ProductName: name,
        Key: serialKey,
        DeviceIdHash: deviceIdHash,
        DeviceName: deviceName
      });

    return fetch(Config.apiUrl + '/userinfo/registerproductfordevice',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: json
      });
  };

  const onSubmit = (data) => {
    tryToRegisterDevice(data.deviceId, "Offline Device")
      .then (HandleResponseNoLogout)
      .then(
        (response) => {
            handleClose();
            window.location.reload(); // force page reload
        },
        error => {
          if (error !== "")
          {
              setError("deviceId", { message: error.replace(/"/g, "") });
          }
          else
          {
             setError("deviceId", "Something went wrong. Please try again later.");
          }
        })
        .catch(redirectToLogin);
  };

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Offline Registration...
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        onSubmit={
          handleSubmit(onSubmit)
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please paste the offline plug-in Device ID.
          <br /><br />
          <Form ref={formRef}>
            <Form.Group >
              <Form.Label htmlFor="deviceId">Device ID:</Form.Label><br />
              <Form.Control
                id="deviceId"
                {...register("deviceId", {
                  required: "required",
                  validate: value => { if (value.length === 32) return true; else return "Please enter a valid Device ID (32 characters long)." },
                })}
              />
              {errors.deviceId && <Form.Text className="text-danger">{errors.deviceId.message}</Form.Text>}<br />
            </Form.Group>
          <Button variant="secondary" type="submit">REGISTER</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}