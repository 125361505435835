import { useEffect, useState } from "react";

const useSizeWidth = () => {
  const [windowSizeWidth, setWindowSizeWidth] = useState(window.innerWidth);

  useEffect(() => {
    const windowSizeWidthHandler = () => {
      if (window.innerWidth !== windowSizeWidth)
      {
        // only take width because of mobile browsers that send window 
        // change events when they hide the url on the top
        setWindowSizeWidth(window.innerWidth); 
      }
    };
    window.addEventListener("resize", windowSizeWidthHandler);

    return () => {
      window.removeEventListener("resize", windowSizeWidthHandler);
    };
  }, [windowSizeWidth]);

  return windowSizeWidth;
};

export default useSizeWidth;